.professional_main h2 {
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    position: relative;
}

.professional_main {
    display: flex;
    flex-direction: column;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    animation: wave 1.3s linear infinite;
}

.professional_main h2:nth-child(2) {
    font-style: italic;
}

.professional_main h2:nth-child(2) span:nth-child(1) {
    margin-left: 10px;
}

.professional_main h2:nth-child(2) span:nth-child(2) {
    animation-delay: -1.1s;
    margin-left: 5px;
}

.professional_main h2:nth-child(2) span:nth-child(3) {
    animation-delay: -0.9s;
    margin-left: 5px;
}

.professional_main {
    margin: 2em 20px 6em;
}

@keyframes wave {

    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-15px);
    }
}

@media only screen and (min-width: 640px) {

    .professional_main {
        flex-direction: row;
        margin: 5em 2em 8em;
    }

    .professional_main h2 {
        font-size: 3rem;
    }

    .professional_main h2:nth-child(2) {
        margin-left: 15px;
    }
}

@media only screen and (min-width: 1024px) {

    .professional_main {
        margin: 8em 5em 13em;
    }
}