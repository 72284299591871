.section_2 {
    position: relative;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_2_inner {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ellipse_1 {
    position: absolute;
    top: 15%;
    width: 95%;
}

.section_2 h2 {
    margin: 3em 0 2em 0;
    text-transform: uppercase;
    font-weight: 600;
    mix-blend-mode: revert;
}

.section_2 h4 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 5px;
}

.about_link {
    text-transform: capitalize;
    text-decoration: none;
    margin: 0 auto;
    width: 11rem;
    display: block;
}

.hidden_div,
.hidden_div_2 {
    display: inline-block;
    transition: .5s ease;
    width: 0em;
}

.hidden_div {
    background: url('../../images/about_me_small.webp');
    height: 1.3em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about_link:hover .hidden_div {
    height: 1.3em;
    width: 1.6em;
    margin: 0 5px;
}

.about_link:hover .hidden_div_2 {
    width: .8em;
}

.about_link>span:nth-child(1) {
    border-bottom: 1.5px solid;
}

.about_link>.right_arrow {
    transform: translateX(-5px);
    margin: 0 10px;
}

.DOB {
    position: absolute;
    top: 7%;
    right: 5rem;
    font-weight: 200;
}

.right_arrow {
    width: 1rem;
    rotate: -90deg;
    width: 1rem;
}

.hero_me {
    width: 40%;
    object-fit: cover;
    max-width: 14em;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
}

.mech_to_web {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 2em;
    padding-left: 20px;
    max-width: 640px;
}

.mech_to_web_desktop {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.mech_to_web_desktop h5 {
    /* color: #1D8BCF; */
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 5px;
}

.mech_to_web_desktop_text_image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    padding-left: 2rem;
}

.languages_follow_desktop {
    width: 45%;
}

.languages_follow_desktop ul>li {
    font-size: .8rem;
    padding-bottom: 5px;
}

.follow_me_desktop {
    /* border-bottom: 1px solid #1D8BCF; */
    margin-bottom: 20px;
}

.follow_me_desktop ul {
    display: flex;
    gap: 1em;
    padding-bottom: 10px;
}

.follow_me_desktop ul a {
    /* border-bottom: 1.3px solid #1D8BCF; */
    transition: 70ms ease;
}

.follow_me_desktop ul a:hover {
    border-bottom: 0px solid !important;
}

.languages_desktop h5 {
    /* border-bottom: 1px solid #1D8BCF; */
    margin-bottom: 10px;
}

.mech_to_web_text_desktop {
    display: flex;
    flex-direction: column;
}

.mech_to_web_text_desktop h4 {
    text-transform: capitalize;
}

.mech_to_web_text_desktop>div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.mech_to_web_text_desktop>div>p {
    padding: 0;
    text-transform: capitalize;
}

.mech_to_web_text_desktop>div>img {
    width: .7em;
    transform: rotate(-90deg);
}

.mech_to_web_desktop ul li,
a,
p {
    /* color: #1D8BCF; */
    text-decoration: none;
    list-style: none;
}

.mech_to_web_desktop p {
    font-size: .6rem;
    padding-bottom: 10px;
    line-height: 15px;
}

.mech_to_web_text {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    justify-content: center;
    width: 57%;
    max-width: 320px;
    margin: 0 auto;
}

.mech_to_text_para {
    font-weight: 300;
    display: flex;
    justify-content: space-around;
    font-size: 8.5px;
    margin-bottom: 2em;
}

.mech_to_text_para p {
    width: 35%;
}

.mech_to_text_para img {
    width: 0.5rem;
}

.languages_follow {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.languages_follow h5 {
    /* color: #1D8BCF; */
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;

    margin-bottom: 10px;
    padding-bottom: 5px;
}

.languages_follow li {
    /* color: #1D8BCF; */
    list-style: none;
    font-size: 13px;
    padding-bottom: 5px;
}

.languages {
    width: 45%;
}

.languages ul {
    padding-left: 20px;
    font-weight: 400;
}

.languages h5 {
    padding-left: 20px;
}

.follow_me {
    width: 45%;
}

.follow_me h5 {
    padding-right: 20px;
}

.section_3 {
    position: relative;
    padding: 21em 0 10em 0;
}

/* ROTATING BOX */
#wrapper {
    position: absolute;
    left: 50%;
    top: 10em;
    perspective: 1500px;
}

.box-area {
    position: relative;
    transform-style: preserve-3d;
    animation-name: rotate;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.box {
    position: absolute;
    width: 150px;
    height: 150px;
    box-shadow: inset 0 0 100px #02203c;
    opacity: .75;
    outline: 1px solid #02203c;
    background-size: 100% 100%;
    background-color: #1D8BCF;
}

#box-front {
    transform: translateX(-75px) translateY(-75px) translateZ(75px);
    background: url('../../images/css.webp');
    background-position: center;
    background-size: cover;
}

#box-back {
    transform: translateX(-75px) translateY(-75px) translateZ(-75px);
    background-image: url('../../images/html.webp');
    background-position: center;
    background-size: cover;
}

#box-right {
    transform: translateY(-75px) rotateY(90deg);
    background-image: url('../../images/reactJs.webp');
    background-size: cover;
}

#box-left {
    transform: translateY(-75px) translateX(-150px) rotateY(90deg);
    background-image: url('../../images/js.webp');
    background-size: cover;
}

#box-top {
    transform: translateX(-75px) translateY(-150px) rotateX(90deg);
}

#box-bottom {
    transform: translateX(-75px) rotateX(90deg);
}

.box-area {
    transform: rotateX(90deg) rotateY(90deg);
}

@keyframes rotate {
    0% {
        transform: rotate3d(0, 0, 0, 0);
    }

    100% {
        transform: rotate3d(0, 1, 0, 360deg);
        ;
    }
}

/* BOX ENDS */

.mechanical_software {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 3em;
    max-width: 768px;
}

.mechanical_software h5 {
    display: inline-block;
    /* color: #1D8BCF; */
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
    /* border-bottom: 1px solid #1D8BCF; */
    margin-bottom: 10px;
    padding: 0 20px;
    padding-bottom: 5px;
}

.mechanical_software p {
    font-size: 13px;
    padding-bottom: 5px;
    font-weight: 400;
}

.mechanical_software img {
    width: 8em;
    margin: 2em auto;
}

.mechanical_software img:hover {
    animation: shake .3s;
    animation-iteration-count: 1;
}

.mechanical {
    text-align: right;
}

.mechanical p {
    padding-right: 20px;
}

.software {
    text-align: left;
}

.software p {
    padding-left: 20px;
}

.selected_work_container {
    position: relative;
}

.selected_work_arrow:hover {
    transform: translateY(120%);
}

.selected_work {
    width: 100%;
    height: 10em;
    object-fit: cover;
}

.selected_work_text {
    height: 5em;
    position: absolute;
    top: 4%;
    width: 100%;
    font-weight: 600;
    font-size: 2.5rem;
    padding-left: 20px;
}

.selected_work_text>p {
    color: #125C89 !important;
}

.selected_work_arrow {
    transition: 0.5s ease;
    position: absolute;
    right: 20px;
    bottom: 0;
    transform: translateY(50%);
}

.section_4>p {
    /* color: #1D8BCF; */
    text-align: center;
    border-top: 1.5px solid #1D8BCF;
    padding: 10px 0 50px 0;
    font-weight: 500;
}

.see_more {
    margin: 6em auto;
    width: 14em;
    position: relative;
}

.see_more a {
    text-decoration: none;
    /* border-bottom: 1.5px solid #1D8BCF; */
    padding-top: 10px;
}

.see_more span {
    text-transform: uppercase;
    /* color: #1D8BCF; */
    font-size: 1.3rem;
}

.see_more span:nth-child(3) {
    margin-right: 10px;
}

.see_more div {
    display: inline-block;
    transition: 0.5s ease;
}

.see_more img {
    width: 1.2em;
    transform: rotate(-90deg);
    margin-left: 15px;
    position: absolute;
}

.see_more div:nth-child(2) {
    background: url('../../images/pokemon.webp');
    width: 0;
    height: 1.3em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.see_more div:nth-child(5) {
    background: url('../../images/algoexpert.webp');
    width: 0;
    height: 1.3em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.see_more:hover div:nth-child(2),
.see_more:hover div:nth-child(5) {
    width: 2em;
    margin: 0 5px;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(5deg);
    }

    20% {
        transform: rotate(10deg);
    }

    30% {
        transform: rotate(5deg);
    }

    40% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-5deg);
    }

    60% {
        transform: rotate(-10deg);
    }

    70% {
        transform: rotate(-5deg);
    }

    80% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(10deg);
    }
}

@media only screen and (min-width: 500px) {
    .mech_to_web_text {
        width: 45%;
    }

    .mech_to_text_para p {
        font-size: 10px;
    }
}

@media only screen and (min-width: 640px) {

    .mechanical p,
    .mechanical_software h5 {
        padding-right: 2rem;
    }

    .software p,
    .mechanical_software h5 {
        padding-left: 2rem;
    }

    .mech_to_web_desktop {
        display: flex;
    }

    .mech_to_web,
    .languages_follow {
        display: none;
    }

    .section_2 h2 {
        margin: 3em 0;
    }

    .ellipse_1 {
        position: absolute;
        top: 0;
        width: 80%;
        max-width: 700px;
    }

    .hero_container>h1 {
        font-size: 4rem;
    }

    .selected_work_text {
        padding-left: 2rem;
    }

    .mech_to_web_desktop_text_image {
        flex-direction: column-reverse;
        text-align: center;
        gap: 30px;
    }

    .hero_me {
        width: 110%;
        transition: 0.5s ease;
    }
}

@media only screen and (min-width: 768px) {

    .DOB {
        font-size: 1.3rem;
    }

    .animation_border_home {
        position: relative;
    }

    .animation_border_home_left_top {
        opacity: 0;
        position: absolute;
        top: -20px;
        left: -20px;
        width: 0;
        height: 0;
        transition: 0.5s ease;
    }

    .animation_border_home_right_bottom {
        opacity: 0;
        position: absolute;
        bottom: -20px;
        right: -20px;
        width: 0;
        height: 0;
        transition: 0.5s ease;
    }

    .animation_border_home:hover .animation_border_home_left_top,
    .animation_border_home:hover .animation_border_home_right_bottom {
        opacity: 1;
        width: 100%;
        height: 100%;
    }

    .animation_border_home:hover .hero_me {
        scale: 1.05;
    }

    .software>h5 {
        width: 100%;
    }

    .selected_work_arrow {
        right: 7%;
        bottom: 50%;
        width: 4em;
    }

    .selected_work {
        width: 65%;
        height: 15em;
    }

    .selected_work_text {
        font-size: 3rem;
        top: 12%;
    }

    #wrapper {
        left: 27em;
        top: 35%;
        margin-left: -15em;
    }

    /* box animation */
    .box {
        position: absolute;
        width: 300px;
        height: 300px;
    }

    #box-front {
        transform: translateX(-100px) translateY(-100px) translateZ(100px);
    }

    #box-back {
        transform: translateX(-100px) translateY(-100px) translateZ(-100px);
    }

    #box-right {
        transform: translateY(-100px) rotateY(90deg);
    }

    #box-left {
        transform: translateY(-100px) translateX(-200px) rotateY(90deg);
    }

    #box-top {
        transform: translateX(-100px) translateY(-200px) rotateX(90deg);
    }

    #box-bottom {
        transform: translateX(-100px) rotateX(90deg);
    }

    .box {
        width: 200px;
        height: 200px;
    }

    .mechanical_software {
        width: 50%;
        margin: unset;
        margin-bottom: 3em;
    }

    .animation_mechanical_software_container {
        display: flex;
        justify-content: space-between;
    }

    .section_3 {
        padding: 10em 0 10em 0;
    }
}

@media only screen and (min-width: 1024px) {

    #wrapper {
        position: absolute;
        left: 31em;
        top: 35%;
        perspective: 1500px;
        margin-left: -15em;
    }

    .box {
        position: absolute;
        width: 300px;
        height: 300px;
    }

    #box-front {
        transform: translateX(-150px) translateY(-150px) translateZ(150px);
    }

    #box-back {
        transform: translateX(-150px) translateY(-150px) translateZ(-150px);
    }

    #box-right {
        transform: translateY(-150px) rotateY(90deg);
    }

    #box-left {
        transform: translateY(-150px) translateX(-300px) rotateY(90deg);
    }

    #box-top {
        transform: translateX(-150px) translateY(-300px) rotateX(90deg);
    }

    #box-bottom {
        transform: translateX(-150px) rotateX(90deg);
    }

    .box {
        width: 300px;
        height: 300px;
    }

    .mechanical p,
    .mechanical_software h5 {
        padding-right: 5rem;
    }

    .mech_to_web_desktop_text_image {
        padding-left: 4rem;
        flex-direction: row;
        text-align: inherit;
        width: 46%;
        gap: 0;
    }

    .mech_to_web_text_desktop {
        padding-right: 15px;
    }

    .mech_to_web_desktop_text_image h4 {
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: capitalize;
        padding-bottom: 5px;
    }

}

@media only screen and (min-width: 1280px) {

    .about_link {
        width: 15rem;
        font-size: 23px;
    }

    .section_2 h2 {
        margin: 2.5em 0;
        font-size: 2rem;
    }

    .mech_to_web_desktop p {
        font-size: .8rem;
        line-height: 22px;
    }

    .mech_to_web_desktop h5 {
        font-size: 1.5rem;
        padding-bottom: 8px;
    }

    .mechanical_software h5 {
        font-size: 1.5rem;
    }

    .mechanical_software {
        margin-bottom: 13em;
    }

    .see_more span {
        font-size: 23px;
        font-weight: 500;
    }

    .see_more {
        margin-bottom: 11em;
    }
}