.footer_parallax {
    position: relative;
}

.footer_parallax_arrow {
    position: absolute;
    left: 20px;
    bottom: 0;
    transform: translateY(50%);
    transition: 0.5s ease;
}

.footer_parallax_arrow:hover {
    transform: translateY(120%);
}

.lets_connect_parent {
    margin-top: 5em;
    display: flex;
    justify-content: space-between;
}

.lets_connect {
    padding-right: 30px;
}

.lets_connect h2 {
    font-weight: 600;
    font-size: 2.5rem;
    border-bottom: 1.3px solid;
    line-height: 40px;
}

.lets_connect h2:nth-child(1) {
    width: 2.6em;
}

.lets_connect p {
    font-size: 12px;
    margin-top: 5px;
}

.form_container {
    display: flex;
    margin-top: 40px;
}

.form_container form {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 20px 0px 0px 20px;
}

.form_container form>h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 10px 0 20px 0;
}

.form_container>div {
    width: 10%;
}

.form_container form input::placeholder,
textarea::placeholder {
    color: #1D8BCF;
    opacity: 0.7;
}

.form_container form input::-webkit-input-placeholder,
.form_container form textarea::-webkit-input-placeholder {
    color: var(--placeholder-color);
}

.form_container form input::-moz-placeholder,
.form_container form textarea::-moz-placeholder {
    color: var(--placeholder-color);
}

.form_container form input::placeholder,
.form_container form textarea::placeholder {
    color: var(--placeholder-color);
}

.form_container form input,
textarea {
    all: unset;
}

.form_container form input {
    margin: 10px 0;
}

.form_container form textarea {
    padding: 20px 0;
}

.form_container form button {
    all: unset;
    cursor: pointer;
    margin: 2em 0;
    width: fit-content;
    display: inline-flex;
    border-radius: 7px;
    padding: 8px 12px 8px 8px;
    transition: 0.3s ease;
}

.submit_arrow {
    width: .7em;
    transform: rotate(-90deg);
    margin-left: 10px;
}

#submit_arrow {
    transition: 0.3s ease;
}

.form_container form button:hover #submit_arrow {
    transform: rotate(-90deg) translatey(15px);
}

.form_container form button:hover {
    padding: 8px 27px 8px 8px;
}

.social_media_container {
    display: flex;
    justify-content: space-around;
    margin-top: 4em;
}

.social_media {
    width: 35%;
    max-width: 200px;
}

.social_media li {
    list-style: none;
    margin-top: 10px;
}

#social_media_arrow {
    transition: 0.3s ease;
}

.social_media a:hover #social_media_arrow {
    transform: rotate(-90deg) translatey(15px);
}

.social_media a {
    text-decoration: underline;
    display: flex;
    justify-content: space-between;
}

.copyright {
    text-align: center;
    font-size: 10px;
    margin-top: 10em;
}

.selected_work_footer {
    width: 100%;
    height: 10em;
    object-fit: cover;
}

@media only screen and (min-width: 640px) {

    .lets_connect {
        padding-right: 50px;
    }

    .form_container form {
        padding: 20px 0px 20px 2rem;
    }
}

@media only screen and (min-width: 768px) {

    .selected_work_footer {
        width: 100%;
        height: 15em;
    }

    .footer_parallax_arrow {
        left: 20%;
        bottom: -40%;
        width: 4em;
    }

    .form_container {
        width: 50%;
    }

    .form_container form {
        width: 100%;
    }

    .form_container>div {
        display: none;
    }

    .social_media_container div:nth-child(1) {
        display: none;
    }

    .form_social_container {
        display: flex;
        justify-content: space-between;
    }

    .social_media_container {
        width: 50%;
        border-top: 1.5px solid;
        margin-top: 40px;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 1024px) {

    .lets_connect {
        padding-right: 5rem;
    }

    .lets_connect h2 {
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 60px;
    }

    .lets_connect p {
        font-size: 16px;
    }

    .form_container form {
        padding: 20px 0px 20px 5rem;
    }
}

@media only screen and (min-width: 1280px) {

    .lets_connect p {
        font-size: 17px;
    }

    .lets_connect h2 {
        border-bottom: 2.3px solid;
    }

    .form_container form>h3 {
        font-size: 1.5rem;
    }

    .form_container form input {
        margin: 20px 0;
    }

    .form_container form textarea {
        padding: 40px 0;
    }

    .form_container form button {
        font-weight: 400;
        font-size: 1.2rem;
    }

    .social_media a {
        font-weight: 400;
        font-size: 1.2rem;
    }

    .lets_connect_parent {
        margin: 5em 0;
    }
}