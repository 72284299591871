.section_1 {
    overflow: hidden;
    height: 98vh;
    position: relative;
}

.home_inner {
    position: absolute;
    top: 10%;
    right: 0;
    width: 65%;
    height: 70%;
}

.hero_container {
    height: 100%;
    width: 100%;
    margin-top: 45px;
    position: relative;
}

.hero_container>h1 {
    position: absolute;
    top: 50%;
    transform: translate(-35%, -50%);
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    text-shadow: 0px 2px 5px #8a8a8a;
    z-index: 3;
    max-width: 11em;
}

.hero_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
}

.animation_arrow {
    position: absolute;
    bottom: 10%;
    left: 10%;
    animation: moveArrowY 1s alternate-reverse infinite;
}

.my-atropos {
    width: 100%;
    height: 100%;
}

.atropos-inner {
    overflow: visible !important;
}

.atropos_animation_border_left_top,
.atropos_animation_border_right_bottom {
    display: none;
}

.hero_container:hover .atropos_animation_border_left_top,
.hero_container:hover .atropos_animation_border_right_bottom {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.atropos_animation_border_left_top,
.atropos_animation_border_right_bottom {
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
    transition: 1s all ease !important;
}

.atropos_animation_border_left_top {
    top: 0;
    left: 0;
    border-top: 2px solid;
    border-left: 2px solid;
}

.atropos_animation_border_right_bottom {
    right: 0;
    bottom: 0;
    border-right: 2px solid;
    border-bottom: 2px solid;
}

@keyframes moveArrowY {
    from {
        transform: translateY(-10px)
    }

    to {
        transform: translateY(20px)
    }
}

@media only screen and (min-width: 1024px) {

    .hero_container {
        width: 90%;
    }
    
    .section_1 {
    overflow: hidden;
    height: 100vh;
    position: relative;
}
}
