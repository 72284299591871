.work_main {
    height: 20vh;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.work_main>a,
.work_main>p {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
}

.active {
    border-bottom: 1.5px solid;
    padding-bottom: 5px;
}

.work_main p {
    margin: 0 10px;
}

@media only screen and (min-width: 640px) {

    .work_main {
        margin-left: 2em;
    }
}

@media only screen and (min-width: 768px) {

    .work_main {
        margin: 2em 0 2em 2em;
    }

    .active {
        border-bottom: 2px solid;
    }
}

@media only screen and (min-width: 1024px) {

    .work_main {
        margin: 2em 0 2em 5em;
    }

    .work_main>a,
    .work_main>p {
        font-size: 1.8rem;
    }
}