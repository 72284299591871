.nav_main {
    background: transparent;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 20;
    width: 100%;
    max-width: 1700px;
    margin: auto;
}

.nav_main>a {
    margin-top: 9px;
    margin-left: 5px;
    font-weight: 500;
    font-size: 1.4rem;
    text-decoration: none;
    width: auto;
    z-index: 10;
}

.desktop_nav {
    display: none;
}

.desktop_nav_links {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktop_nav_links a {
    transition: 70ms ease;
}

.desktop_nav_links a:hover {
    border-bottom: 1.5px solid;
    padding-bottom: 2px;
}

.desktop_nav_links>button {
    all: unset;
    cursor: pointer;
}

.desktop_nav_links>button>img {
    width: 1.3em;
}

a,
button {
    cursor: pointer;
}

nav {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    transform: translate(-50%, -50%);
}

nav ul button {
    all: unset;
    cursor: pointer;
    font-style: italic;
    padding-bottom: 1px;
}

.navigation {
    position: fixed;
    top: 0;
    right: -800px;
    width: 100vw;
    height: 100%;
    transition: .5s;
}

.navigation ul li {
    text-align: left;
    text-transform: uppercase;
    list-style-type: none;
    font-size: 1.5em;
    padding: 20px 30px;
    border-bottom: 1pt solid #d0e2ed;
}

.hamburger,
.bar {
    position: fixed;
}

.hamburger {
    display: block;
    top: 25px;
    right: 20px;
    width: 35px;
    height: 30px;
    transform: translateY(-50%);
    border: 0;
    background: 0 0;
}

.bar {
    top: 3px;
    width: 100%;
    height: 3px;
    transition: all .3s ease-in;
    border-radius: 10px;
}

#bar2 {
    top: 11px;
}

#bar3 {
    top: 19px;
}

.navigation.active {
    right: 0;
}

.hamburger.open #bar1 {
    transform: rotate(45deg) translate(6px, 5px);
}

.hamburger.open #bar3 {
    transform: rotate(-45deg) translate(6px, -5px);
}

@media only screen and (min-width : 768px) {

    .mobile_nav {
        display: none;
    }

    .desktop_nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-right: 40px;
        padding: 10px 0;
    }

    .desktop_nav {
        padding-right: 60px;
    }

    .nav_main>a {
        width: 19em;
    }
}

@media only screen and (min-width : 1024px) {

    .desktop_nav_links {
        width: 50%;
    }

    .desktop_nav {
        padding-right: 110px;
    }

    .nav_main>a {
        width: 17em;
    }
}